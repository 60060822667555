import { lazy, useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import initializeApp from "./app/init";
import SignIn from "./pages/Authentication/SignIn";
import SignUp from "./pages/Authentication/SignUp";
//import toast from 'react-hot-toast';
import ActivateAccount from "./pages/Users/ActivateAccount";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { getUserLogged, getisLogged } from "./features/user/userSlice";

const Layout = lazy(() => import("./containers/Layout"));
initializeApp();

function App() {
  const [loggedin, setLoggedit] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.currentuser);
  useEffect(() => {
    const checkAuth = async () => {
      const result = await dispatch(getisLogged());

      if (result.meta.requestStatus === "fulfilled") {
        setLoggedit(true);
      } else {
        setLoggedit(false);
      }
    };
    checkAuth();
  }, []);
  /*
	const [user, setUser] = useState(null);
	useEffect(() => {
		const fetchCurrentUser = async () => {
			try {
				const response = await axios.get('http://localhost:5000/api/user');
				setUser(response.data.user);
				console.log(user)
			} catch (error) {
				console.error('Error fetching current user', error);
			}

		}
		fetchCurrentUser()
	})*/

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
  }, []);
  useEffect(() => {
    dispatch(getUserLogged());
  }, [dispatch]);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="*"
            element={<Navigate to={loggedin ? "/app/welcome" : "/"} replace />}
          />
          <Route path="/" element={<SignIn />} />

          <Route path="/app/*" element={loggedin ? <Layout /> : <SignIn />} />
          <Route path="/auth/verify-email" element={<ActivateAccount />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
